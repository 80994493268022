import React from 'react';
// import pmcImg from "./images/PM-Cares-qr.png";
// import pmnImg from "./images/PMNRF.jpg";
// import gIndiaImg from "./images/GIVE-INDIA.png";
// import Assam from "./images/Assam.jpg";
// import Karnataka from "./images/Karnataka.jpg";
// import Maharashtra from "./images/Maharashtra.png";
// import AndhraP from "./images/AndhraP.jpg";
// import Rajasthan from "./images/Rajasthan.png";
// import Mizoram from "./images/Mizoram.png";
// import Delhi from "./images/Delhi.png";
// import Telangana from "./images/Telangana.png";
// import TN from "./images/TN.png";
// import Haryana from "./images/Haryana.png";
// import WB from "./images/WB.jpg";
// import Punjab from "./images/Punjab.jpg";
// import Bihar from "./images/Bihar.jpg";
// import MP from "./images/MP.jpg";
// import Kerala from "./images/Kerala.jpg";
// import Meghalaya from "./images/Meghalaya.jpg";
// import Gujarat from "./images/Gujarat.jpg";
import milaap from "./images/milaap.jpg";
import {Event} from "../../utils/event";

export default class Donate extends React.Component {

    state = {
        activeIndex:-1,
        showImage: false
    }

    // setActiveIndex = (index) =>{

    //     this.setState({activeIndex:index });
    // } 
    // createData = (title, upiId, img) => {
    //     return {  title, upiId, img };
    //   }

    // componentDidUpdate(prevProps){
    //         if(prevProps.state !== this.props.state){
    //             this.setState({activeIndex:-1});
    //         }
    // }
    
    // createStateData = (state) => {

    //     switch(state) {
    //         case 'ASSAM':
    //             return this.createData("Chief Minister's Relief Fund , Assam" , "cmrfassam@sbi" , Assam);
    //         case 'KARNATAKA':
    //             return this.createData("Chief Minister's Relief Fund , Karnataka", "cmrfkarnataka" , Karnataka);
    //         case 'MAHARASHTRA':
    //             return this.createData("Chief Minister's Relief Fund COVID-19, Maharashtra","cmrfmahacovid19",Maharashtra);
    //         case 'ANDHRA PRADESH':
    //             return this.createData("Andhra Pradesh Chief Minister's Relief Fund","apcmrelief@andb",AndhraP);
    //         case 'RAJASTHAN':
    //             return this.createData("Rajasthan Chief Minister Relief fund","rajcmrfcovid19",Rajasthan);
    //         case 'MIZORAM':
    //             return this.createData("Chief Minister Relief Fund, Mizoram","cmrfmizoram@hdfcbank",Mizoram);
    //         case 'DELHI':
    //             return this.createData("LG/CM Relief Fund, Delhi","lgcmdelhifund@cnrb", Delhi);
    //         case 'TELANGANA':
    //             return this.createData("Chief Minister Relief Fund, State Of Telangana","tscmrf@sbi",Telangana);
    //         case 'TAMIL NADU':
    //             return this.createData("Tamil Nadu Chief Minister's Public Relief Fund","tncmprf@iob",TN);
    //         case 'HARYANA':
    //             return this.createData("Haryana Corona Relief Fund","hrycoronarelieffund@sbi", Haryana);
    //         case 'WEST BENGAL':
    //             return this.createData("West Bengal State Emergency Relief Fund","wbs.erf@icici", WB);    
    //         case 'PUNJAB':
    //             return this.createData("Punjab Chief Minister Relief Fund","pbcmrf@hdfcbank", Punjab);    
    //         case 'BIHAR':
    //             return this.createData("Chief Minister Relief Fund, Bihar","cmrfbihar@idbi", Bihar);                 
    //         case 'MADHYA PRADESH':
    //             return this.createData("Madhya Pradesh Chief Minister's Relief Fund","chiefministerrelieffund", MP);                 
    //         case 'KERALA':
    //             return this.createData("Chief Minister's Distress Relief Fund, Kerala","keralacmdrf@sbi", Kerala);
    //         case 'MEGHALAYA':
    //             return this.createData("Chief Minister's Relief Fund, Meghalaya","cmrfmeghalaya@sbi", Meghalaya);
    //         case 'GUJARAT':
    //             return this.createData("Gujarat Chief Minister Relief Fund","gujaratcmrelieffund@sbi" ,Gujarat );
    //         default:
    //              return {title:"null" ,upiId: "null" ,img: "null"}
    //         }
    // }
      
    
    render(){
    
    // const rows = [
    //     this.createData("PM Cares-Prime Minister's Citizen Assistance And Relief In Emergency Situation Fund", "pmcares@sbi", pmcImg),
    //     this.createData("Prime Minister's National Relief Fund, (PMNRF)", "pmnrf@centralbank" ,  pmnImg),
    //     this.createData("Give India Foundation", "giveindia2020@icici" ,  gIndiaImg),
    // ];
   
    // if(this.props.state){
    //     const obj = this.createStateData(this.props.state.toUpperCase());
    //     if(obj.title !== "null")
    //     rows.push(obj);
    // }
     
    return (
        <div className="contentTable">
           <table className="contentTable">
    <thead>
      <tr>
        <th colSpan="2">Join The Better India's campaign #BetterTogether in partnership with civil service officers</th>
      </tr>
    </thead>
    <tbody>
        {/* {
       rows.map((item, index) => {
        return ( */}
          <tr>
            <td ><img  className="donateImage" src={milaap}  alt="Milaap Image" height="140" width="165"/></td>
            <td style={{fontFamily:'Times New Roman'}}>Contribute to help daily wage earners, migrant workers and frontline health workers. 100% of funds go to beneficiaries.</td>
            {/* { 
            this.state.activeIndex !== index && 
            <td><button className="donateButton" onClick={()=>this.setActiveIndex(index)}>Click to scan QR</button></td>
                }
            { index === this.state.activeIndex  && <td ><img  className="donateImage" src={item.img}  alt="QR CODE" height="160" width="160"/></td>} */}
        </tr>
    </tbody>
 </table>

    <a style={{ color:'#014798' , fontWeight:300}} 
        href="https://milaap.org/fundraisers/RiseAgainstCOVID19" target="_blank" rel="noopener noreferrer"><button onClick={()=> {Event('DONATE_CLICK', "User clicked on donate button")}} className="donateButton"  style={{textAlign:'center' , marginTop:'1rem' , marginBottom:'1rem'}}>Donate Now</button></a>    
        </div>       
    );
  }
}
