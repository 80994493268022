import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import ReactGA from 'react-ga';
import { WhatsappShareButton, WhatsappIcon, TwitterShareButton, TwitterIcon, LinkedinShareButton, LinkedinIcon, FacebookShareButton, FacebookIcon } from 'react-share';
import { hotjar } from 'react-hotjar';

import Loader from "./components/Loader/loader"
import tbiLogo from "./components/Map/tbi-logo.png";
import * as Icon from 'react-feather';
import {Event} from "./utils/event";
import './App.scss';
import TabPanel from './components/TabPanel/tabPanel';
import MapExplorer from './components/Map/mapexplorer';


function App() {
  const [states, setStates] = useState([]);
  const [stateTestData, setStateTestData] = useState({});
  const [state, setState] = useState('ALL_INDIA');
  const [fetched, setFetched] = useState(false);
  const [lastUpdated, setLastUpdated] = useState('');
  const [activeStateCode, setActiveStateCode] = useState('TT'); // TT -> India
  const [regionHighlighted, setRegionHighlighted] = useState(undefined);
  const [news, setNews] = useState([]);
  const [allNews, setAllNews] = useState([]);
  const [restNews , setRestNews] = useState([]);

  useEffect(() => {
    ReactGA.initialize('UA-4962422-13');
    ReactGA.pageview('/');
    hotjar.initialize('1777813');
  } , []);
  useEffect(() => {
    if (fetched === false) {
      getStates();
    }
  }, [fetched]);

  useEffect( () => {
    if (allNews.length === 0) {
      axios.get('https://karnival-life-app.s3.ap-south-1.amazonaws.com/static-asset/news.json')
      .then( (res) => {
        setNews(res.data);
        setAllNews(res.data); 
        setRestNews(res.data) 
        })
      .catch(err => console.error(err));
    }
  }, [allNews]);


  const getStates = async () => {
    try {
      const [
        response,
        stateTestResponse,
      ] = await Promise.all([
        axios.get('https://api.covid19india.org/data.json'),
        axios.get('https://api.covid19india.org/state_test_data.json'),
      ]);
      setStates(response.data.statewise);
      setLastUpdated(response.data.statewise[0].lastupdatedtime);
      setStateTestData(stateTestResponse.data.states_tested_data.reverse());
      setFetched(true);
    } catch (err) {
      console.log(err);
    }
  };

  const onHighlightState = (state, index) => {
    if (!state && !index) return setRegionHighlighted(null);
    //console.log(state);
    setRegionHighlighted({ state, index });
  };
  const onHighlightDistrict = (district, state, index) => {
    if (!state && !index && !district) return setRegionHighlighted(null);

  };

  const onMapHighlightChange = useCallback(({ statecode }) => {
    setActiveStateCode(statecode);
  }, []);

  const refs = [useRef(), useRef()];

  // const executeScroll = () => {
  //   if (window.innerWidth <= 769) { window.scrollTo({ top: refs[1].current.offsetTop - 100, behavior: 'smooth' }); }

  // }

  useEffect( () => {
    if (window.innerWidth <= 769)
     { window.scrollTo({ top: refs[1].current.offsetTop - 100, behavior: 'smooth' }); 
    }}, [state] );

  const fetchStateNews = useCallback((state) => {

    // const stateNews = allNews.filter((item) => {
    //   return item.STATE.toLowerCase() === state.toLowerCase()
    // });
    // const restNews =  allNews.filter((item) => {
    //   return item.STATE.toLowerCase() !== state.toLowerCase()
    // });
    
    let stateNews=[];
    let restNews=[];

    for( let i = 0 ; i < allNews.length; i++){
      if(allNews[i].State.toLowerCase() === state.toLowerCase()){
        stateNews.push(allNews[i]);
      }
      else{
        restNews.push(allNews[i]);
      }
    }
    // console.log('allmews' , allNews);
    // console.log('stateNews ', stateNews);
    // console.log('restNews ', restNews);
    
    if (stateNews.length !== 0) {
      setNews(stateNews);
      setRestNews(restNews);
      setState(state);
   
      // console.log('length ', stateNews.length);
    }
    else if(restNews.length !== 0){
      setNews([])
      setRestNews(allNews);
      setState(state);
    }

  }, [state, news, allNews,restNews]);
  const shareUrl = `${window.location.href}?utm_source=Covdtracker&utm_medium=SharedButton&utm_campaign=April17`;
 // const twitterShareUrl = 'https://twitter.com/intent/tweet?text=Hey,%20check%20out%20this%20amazing%20page%20that%20I%27ve%20found.%20Also%20do%20spread%20the%20goodness!&url=covid19.thebetterindia.com%3Futm_source%3DCovdtracker%26utm_medium%3DSharedButton%26utm_campaign%3DApril17&original_referer=https%3A%2F%2Ftwitter.com%2Fshare%3Furl%3Dcovid19.thebetterindia.com%253Futm_source%253DCovdtracker%2526utm_medium%253DSharedButton%2526utm_campaign%253DApril17'
  
  const title = `Hey, checkout this amazing page that I've found. Also do spread the goodness! `;
 
  return (
    <React.Fragment>
      <div className="Home">
        <div className="home-left">
        <div
      className="MapExplorer fadeInUp" >

       <div className="header">
        <div style={{ lineHeight: '1.3', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'spaceAround' }}>           <div>
            <a href="https://www.thebetterindia.com/?utm_source=Covdtracker&utm_medium=headerlink&utm_campaign=April17" target="_blank" rel="noopener noreferrer"><img style={{ display: 'inline' , marginTop:'0.5rem'}} align="left" alt="The Better India" src={tbiLogo} height="72" width="70" /></a>
          </div>
          <div style={{ display: 'inline', marginLeft: '1rem', marginBottom: '0.6rem' }}>
            <h3 style={{ marginBottom: '0.3rem', color:'black' }}><b>COVID-19 POSITIVE NEWS TRACKER</b></h3>
            <h6 style={{ textTransform: 'none', fontSize: '12px' }}>There is positive news everywhere, if you simply look in the right places. Don't believe us? See for yourself! Click on your state in the map below for positive news amidst this crisis.</h6>
          </div>
        </div>
        <hr style={{ marginBottom: '0.9rem' }}></hr>
        <div style={{ display: 'flex' }}>
          <h6 style={{ marginTop:'0.4rem' , marginLeft: '0.8rem', display: 'inline', fontSize: '12px' , color:'black' }}>
            Select a state/ut from the map below
        </h6>
          <div className="share-button" style={{marginRight:'0.8rem'}}> 
          <span style={{marginTop:'0.3rem' }}> <Icon.Share2 size={10}/> Share </span>      
            <WhatsappShareButton
             style={{marginRight:'0.3rem'}}
              url={shareUrl}
              title={title}
            >
              <WhatsappIcon size={20} />
            </WhatsappShareButton>
            <FacebookShareButton
            style={{marginRight:'0.3rem'}}
              url={shareUrl}
              quote={title}
            >
              <FacebookIcon size={20} />
            </FacebookShareButton>
           
            <TwitterShareButton
              url={shareUrl}
              title={title}
            style={{marginRight:'0.3rem'}}
            >
              <TwitterIcon size={20} />
            </TwitterShareButton>
           <LinkedinShareButton
              url={shareUrl}
              title={title}
            style={{marginRight:'0.3rem'}}
            >
              <LinkedinIcon size={20} />
            </LinkedinShareButton>
           
       </div>           
        </div>
      </div>
      </div>
      {fetched === false && <Loader />}  
       
          {fetched && (
            <React.Fragment>
              <MapExplorer
                forwardRef={refs[0]}
                states={states}
                stateTestData={stateTestData}
                regionHighlighted={regionHighlighted}
                onMapHighlightChange={onMapHighlightChange}
                fetchStateNews={fetchStateNews}
              />
            </React.Fragment>
          )}

        </div>
        
        <div className="home-right" style={{ marginTop: '1rem' }} ref={refs[1]}>
          {fetched && <TabPanel news={news} state={state} restNews={restNews} />}
        </div>

      </div>
      <footer className="fixed-footer" style={{animationDelay: '1.5s' , marginTop: '3rem' , textAlign:'center'}}>

        {fetched &&  
        
        <div className="content">
          <h5 style={{color:'#014798' , textTransform:'none'}}>Made by <a style={{color:'#014798'}} onClick={()=> {Event('FOOTER_CLICK', `User clicked on footer`); return true;}} href="https://www.thebetterindia.com/?utm_source=Covdtracker&utm_medium=footerlink&utm_campaign=April17" target="_blank" rel="noopener noreferrer">The Better India</a> Team </h5>
          <div style={{width:'95%'}}>
          <p style={{fontFamily:'Poppins' , textTransform:'none'}}>
          We are India’s only and Asia's largest impact journalism platform, that uses story-telling to drive large scale change and impact!    
          </p>
          <p style={{fontFamily:'Poppins' , textTransform:'none'}}>
          We love to tell stories of change, innovation, and heroics of ordinary citizens... and we love how these stories inspire us and our readers into action! 
          </p>
          <p style={{fontFamily:'Poppins' , textTransform:'none'}}>
          Over the years we've raised lakhs in relief money, brought recognition and national awards to many of our protagonists, highlighted issues that brought about policy level change and  <a  style={{color:'#014798'}} href="https://www.thebetterindia.com/impact/" target="_blank" rel="noopener noreferrer">   much more!</a> 
          </p>
          <p style={{fontFamily:'Poppins' , textTransform:'none'}}>
           If you believe in our cause and would like to support us in this movement, <a style={{color:'#014798'}} href="https://www.thebetterindia.com/supporting-better-india/" target="_blank" rel="noopener noreferrer"> click here</a>.
          </p>
          </div>
         </div>}
        </footer>
        
    </React.Fragment>
  );
}

export default App;
