import React from "react";
import { css } from "@emotion/core";
import PulseLoader from "react-spinners/PulseLoader";
 
// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  position: fixed, top: 50%, left: 50%, transform: translate(-50%, -50%)
`;
 
export default class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    };
  }
 
  render() {
    return (
      <div className="sweet-loading" style={{position: 'fixed', top: '50%', left: '50%', transform:'translate(-50%, -50%)'}}>
        <PulseLoader
          size={15}
          color={"#62EACA"}
          loading={this.state.loading}
        />
      </div>
    );
  }
}