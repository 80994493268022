import React from 'react';
// import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';


import News from './News';
import Donate from './Donate';


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

// TabPanel.propTypes = {
//   children: PropTypes.node,
//   index: PropTypes.any.isRequired,
//   value: PropTypes.any.isRequired,
// };

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper
  },
}));

export default function FullWidthTabs({news , state , restNews}) {
  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  return (
   <div className={classes.root } style={{marginTop:'0.6rem' , maxHeight:'100vh' , overflowY: 'auto' , zIndex: 0}}>
      <AppBar position="static" color="default" style= {{ position:'sticky' , top:0  }}>
        <Tabs 
         TabIndicatorProps={{style: {background:'#014798' }}}
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
        >
          <Tab label={<span style={{ color: '#014798' , fontWeight:'bold'}}>Positive Stories</span>} {...a11yProps(0)} />
          <Tab label={<span style={{ color: '#014798', fontWeight:'bold'}}>Donate</span>} {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          <News news={news} state={state} restNews={restNews}/>
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          <Donate state={state}/>
        </TabPanel>
      </SwipeableViews>
    </div>
    );
}
