import React, { useState, useEffect, useMemo, useCallback } from 'react';
import ChoroplethMap from './choropleth';
import { MAP_TYPES, MAPS_DIR } from '../../constants';
import { formatDate, formatDateAbsolute } from '../../utils/common-functions';
import { formatDistance, format, parse } from 'date-fns';
import { formatNumber } from '../../utils/common-functions';
import * as Icon from 'react-feather';

const mapMeta = {
  India: {
    name: 'India',
    geoDataFile: `${MAPS_DIR}/india.json`,
    mapType: MAP_TYPES.COUNTRY,
    graphObjectName: 'india',
  },
  'Andaman and Nicobar Islands': {
    name: 'Andaman and Nicobar Islands',
    geoDataFile: `${MAPS_DIR}/andamannicobarislands.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'andamannicobarislands_district',
  },
  'Arunachal Pradesh': {
    name: 'Arunachal Pradesh',
    geoDataFile: `${MAPS_DIR}/arunachalpradesh.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'arunachalpradesh_district',
  },
  'Andhra Pradesh': {
    name: 'Andhra Pradesh',
    geoDataFile: `${MAPS_DIR}/andhrapradesh.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'andhrapradesh_district',
  },

  Assam: {
    name: 'Assam',
    geoDataFile: `${MAPS_DIR}/assam.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'assam_district',
  },
  Bihar: {
    name: 'Bihar',
    geoDataFile: `${MAPS_DIR}/bihar.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'bihar_district',
  },
  Chandigarh: {
    name: 'Chandigarh',
    geoDataFile: `${MAPS_DIR}/chandigarh.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'chandigarh_district',
  },
  Chhattisgarh: {
    name: 'Chhattisgarh',
    geoDataFile: `${MAPS_DIR}/chhattisgarh.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'chhattisgarh_district',
  },
  'Dadra and Nagar Haveli': {
    name: 'Dadra and Nagar Haveli',
    geoDataFile: `${MAPS_DIR}/dadranagarhaveli.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'dadranagarhaveli_district',
  },
  Delhi: {
    name: 'Delhi',
    geoDataFile: `${MAPS_DIR}/delhi.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'delhi_district',
  },
  Karnataka: {
    name: 'Karnataka',
    geoDataFile: `${MAPS_DIR}/karnataka.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'karnataka_district',
  },
  Kerala: {
    name: 'Kerala',
    geoDataFile: `${MAPS_DIR}/kerala.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'kerala_district',
  },
  Goa: {
    name: 'Goa',
    geoDataFile: `${MAPS_DIR}/goa.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'goa_district',
  },
  Gujarat: {
    name: 'Gujarat',
    geoDataFile: `${MAPS_DIR}/gujarat.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'gujarat_district',
  },
  Haryana: {
    name: 'Haryana',
    geoDataFile: `${MAPS_DIR}/haryana.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'haryana_district',
  },
  'Himachal Pradesh': {
    name: 'Himachal Pradesh',
    geoDataFile: `${MAPS_DIR}/himachalpradesh.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'himachalpradesh_district',
  },
  'Jammu and Kashmir': {
    name: 'Jammu and Kashmir',
    geoDataFile: `${MAPS_DIR}/jammukashmir.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'jammukashmir_district',
  },
  Jharkhand: {
    name: 'Jharkhand',
    geoDataFile: `${MAPS_DIR}/jharkhand.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'jharkhand_district',
  },
  Ladakh: {
    name: 'Ladakh',
    geoDataFile: `${MAPS_DIR}/ladakh.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'ladakh_district',
  },
  Lakshadweep: {
    name: 'Lakshadweep',
    geoDataFile: `${MAPS_DIR}/lakshadweep.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'lakshadweep_district',
  },
  'Madhya Pradesh': {
    name: 'Madhya Pradesh',
    geoDataFile: `${MAPS_DIR}/madhyapradesh.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'madhyapradesh_district',
  },
  Maharashtra: {
    name: 'Maharashtra',
    geoDataFile: `${MAPS_DIR}/maharashtra.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'maharashtra_district',
  },
  Manipur: {
    name: 'Manipur',
    geoDataFile: `${MAPS_DIR}/manipur.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'manipur_district',
  },
  Meghalaya: {
    name: 'Meghalaya',
    geoDataFile: `${MAPS_DIR}/meghalaya.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'meghalaya_district',
  },
  Mizoram: {
    name: 'Mizoram',
    geoDataFile: `${MAPS_DIR}/mizoram.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'mizoram_district',
  },
  Nagaland: {
    name: 'Nagaland',
    geoDataFile: `${MAPS_DIR}/nagaland.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'nagaland_district',
  },
  Odisha: {
    name: 'Odisha',
    geoDataFile: `${MAPS_DIR}/odisha.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'odisha_district',
  },
  Puducherry: {
    name: 'Puducherry',
    geoDataFile: `${MAPS_DIR}/puducherry.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'puducherry_district',
  },
  Punjab: {
    name: 'Punjab',
    geoDataFile: `${MAPS_DIR}/punjab.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'punjab_district',
  },
  Rajasthan: {
    name: 'Rajasthan',
    geoDataFile: `${MAPS_DIR}/rajasthan.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'rajasthan_district',
  },
  Sikkim: {
    name: 'Sikkim',
    geoDataFile: `${MAPS_DIR}/sikkim.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'sikkim_district',
  },
  'Tamil Nadu': {
    name: 'Tamil Nadu',
    geoDataFile: `${MAPS_DIR}/tamilnadu.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'tamilnadu_district',
  },
  Telangana: {
    name: 'Telangana',
    geoDataFile: `${MAPS_DIR}/telangana.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'telangana_district',
  },
  Tripura: {
    name: 'Tripura',
    geoDataFile: `${MAPS_DIR}/tripura.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'tripura_district',
  },
  Uttarakhand: {
    name: 'Uttarakhand',
    geoDataFile: `${MAPS_DIR}/uttarakhand.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'uttarakhand_district',
  },
  'Uttar Pradesh': {
    name: 'Uttar Pradesh',
    geoDataFile: `${MAPS_DIR}/uttarpradesh.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'uttarpradesh_district',
  },

  'West Bengal': {
    name: 'West Bengal',
    geoDataFile: `${MAPS_DIR}/westbengal.json`,
    mapType: MAP_TYPES.STATE,
    graphObjectName: 'westbengal_district',
  },
};

const getRegionFromState = (state) => {
  if (!state) return;
  const region = { ...state };
  if (!region.name) region.name = region.state;
  return region;
};

// const getRegionFromDistrict = (districtData, name) => {
//   if (!districtData) return;
//   const region = {...districtData};
//   if (!region.name) region.name = name;
//   return region;
// };

function MapExplorer({
  forwardRef,
  states,
  stateDistrictWiseData,
  stateTestData,
  regionHighlighted,
  onMapHighlightChange,
  fetchStateNews
}) {
  const [selectedRegion, setSelectedRegion] = useState({});
  const [panelRegion, setPanelRegion] = useState(getRegionFromState(states[0]));
  const [state, setState] = useState("ALL_INDIA")
  const [currentHoveredRegion, setCurrentHoveredRegion] = useState(
    getRegionFromState(states[0])
  );
  const [testObj, setTestObj] = useState({});
  const [currentMap, setCurrentMap] = useState(mapMeta.India);

  const [statistic, currentMapData] = useMemo(() => {
    const statistic = { total: 0, maxConfirmed: 0 };
    let currentMapData = {};

    if (currentMap.mapType === MAP_TYPES.COUNTRY) {
      currentMapData = states.reduce((acc, state) => {
        if (state.state === 'Total') {
          return acc;
        }
        const confirmed = parseInt(state.confirmed);
        statistic.total += confirmed;
        if (confirmed > statistic.maxConfirmed) {
          statistic.maxConfirmed = confirmed;
        }

        acc[state.state] = state.confirmed;
        return acc;
      }, {});
    } else if (currentMap.mapType === MAP_TYPES.STATE) {
      const districtWiseData = (
        stateDistrictWiseData[currentMap.name] || { districtData: {} }
      ).districtData;
      currentMapData = Object.keys(districtWiseData).reduce((acc, district) => {
        const confirmed = parseInt(districtWiseData[district].confirmed);
        statistic.total += confirmed;
        if (confirmed > statistic.maxConfirmed) {
          statistic.maxConfirmed = confirmed;
        }
        acc[district] = districtWiseData[district].confirmed;
        return acc;
      }, {});
    }
    return [statistic, currentMapData];
  }, [currentMap, states, stateDistrictWiseData]);

  const setHoveredRegion = useCallback(
    (name, currentMap) => {
      if (currentMap.mapType === MAP_TYPES.COUNTRY) {
        const region = getRegionFromState(
          states.find((state) => name === state.state)
        );
        setCurrentHoveredRegion(region);
        setPanelRegion(region);
        onMapHighlightChange(region);
      }
    },
    [states, onMapHighlightChange]
  );

  useEffect(() => {
    if (regionHighlighted === undefined) {
      return;
    } else if (regionHighlighted === null) {
      setSelectedRegion(null);
      return;
    }
    const isState = !('district' in regionHighlighted);
    if (isState) {
      const newMap = mapMeta['India'];
      setCurrentMap(newMap);
      const region = getRegionFromState(regionHighlighted.state);
      setHoveredRegion(region.name, newMap);
      setSelectedRegion(region.name);
    }
  }, [regionHighlighted, setHoveredRegion]);

  const setSelectedState = useCallback(
    (name) => {

      fetchStateNews(name);
      setState(name);
    },
    [state]
  );
  // const setSelectedState =   (name) => {

  //       fetchStateNews(name);
  //       setState(name);
  // }


  // const switchMapToState = useCallback(
  //   (name) => {
  //     const newMap = mapMeta[name];
  //     if (!newMap) {
  //       return;
  //     }
  //     setCurrentMap(newMap);
  //     setSelectedRegion(null);
  //     if (newMap.mapType === MAP_TYPES.COUNTRY) {
  //       setHoveredRegion(states[0].state, newMap);
  //     } else if (newMap.mapType === MAP_TYPES.STATE) {
  //       const {districtData} = stateDistrictWiseData[name] || {};
  //       const topDistrict = Object.keys(districtData)
  //         .filter((name) => name !== 'Unknown')
  //         .sort((a, b) => {
  //           return districtData[b].confirmed - districtData[a].confirmed;
  //         })[0];
  //       setHoveredRegion(topDistrict, newMap);
  //     }
  //   },
  //   [setHoveredRegion, stateDistrictWiseData, states]
  // );

  const { name, lastupdatedtime } = currentHoveredRegion;

  useEffect(() => {
    setTestObj(
      stateTestData.find(
        (obj) => obj.state === panelRegion.name && obj.totaltested !== ''
      )
    );
  }, [panelRegion, stateTestData, testObj]);

  return (

    <div
      className="MapExplorer fadeInUp"
      style={{ animationDelay: '1s' }}
      ref={forwardRef}
    >

      <ChoroplethMap
        statistic={statistic}
        mapMeta={currentMap}
        mapData={currentMapData}
        setHoveredRegion={setHoveredRegion}
        selectedRegion={selectedRegion}
        setSelectedRegion={setSelectedRegion}
        setSelectedState={setSelectedState}
      />

      <span style={{ display: 'flex', fontSize: '10px', justifyContent: 'flex-end', marginRight: '0.8rem' }}>

        <a style={{ color: 'black', fontWeight: 300 }} href="https://github.com/covid19india/covid19india-react" target="_blank" rel="noopener noreferrer">Map Credits</a></span>

      <div className="map-stats">
        <div className="stats fadeInUp" style={{ animationDelay: '1s', marginLeft: ' 0.8rem' }}>
          <h5>{window.innerWidth > 769 && 'Confirmed'}</h5>
          <h6>{window.innerWidth <= 769 && 'Confirmed'}</h6>

          <div className="stats-bottom">
            <h1>{formatNumber(panelRegion.confirmed)}</h1>
            <h6>{}</h6>
          </div>
        </div>

        <div
          className="stats is-blue fadeInUp"
          style={{ animationDelay: '1s' }}
        >
          <h5>{window.innerWidth > 769 && 'Active'}</h5>
          <h6>{window.innerWidth <= 769 && 'Active'}</h6>
          <div className="stats-bottom">
            <h1>{formatNumber(panelRegion.active)}</h1>
            <h6>{}</h6>
          </div>
        </div>

        <div
          className="stats is-green fadeInUp"
          style={{ animationDelay: '1s' }}
        >
          <h5>{window.innerWidth > 769 && 'Recovered'}</h5>
          <h6>{window.innerWidth <= 769 && 'Recovered'}</h6>
          <div className="stats-bottom">
            <h1>{formatNumber(panelRegion.recovered)}</h1>
            <h6>{}</h6>
          </div>
        </div>

        {/* <div
          className="stats is-gray fadeInUp"
          style={{animationDelay: '1s'}}
        >
          <h5>{window.innerWidth <= 769 ? 'Dcsd' : 'Deceased'}</h5>
          <div className="stats-bottom">
            <h1>{formatNumber(panelRegion.deaths)}</h1>
            <h6>{}</h6>
          </div>
        </div>  */}

        {
          <div
            className="stats is-purple tested fadeInUp"
            style={{ animationDelay: '1s', marginRight: '0.9rem' }}
          >
            <h5>{window.innerWidth > 769 && 'Tested'}</h5>
            <h6>{window.innerWidth <= 769 && 'Tested'}</h6>
            <div className="stats-bottom">
              <h1>{formatNumber(testObj?.totaltested)}</h1>
            </div>
            <h6 className="timestamp">
              {!isNaN(parse(testObj?.updatedon, 'dd/MM/yyyy', new Date()))
                ? `As of ${format(
                  parse(testObj?.updatedon, 'dd/MM/yyyy', new Date()),
                  'dd MMM'
                )}`
                : ''}
            </h6>
            {testObj?.totaltested?.length > 1 && (
              <a href={testObj.source} target="_noblank" rel="noreferrer noopener">
                <Icon.Link />
              </a>
            )}
          </div>
        }
      </div>

      <div className="meta fadeInUp" style={{ animationDelay: '1s' }}>

        <h2 style={{ marginLeft: '0.8rem' }}>{name}</h2>
        {lastupdatedtime && (
          <div
            className={`last-update ${
              currentMap.mapType === MAP_TYPES.STATE
                ? 'district-last-update'
                : 'state-last-update'
              }`}
          >
            <h6 style={{ marginRight: '0.8rem', color: 'black', fontWeight: '300' }}>Last Updated</h6>
            <h3 style={{ marginRight: '0.8rem', color: 'black', fontSize: '0.3rem', fontWeight: '300' }}
              title={
                isNaN(Date.parse(formatDate(lastupdatedtime)))
                  ? ''
                  : formatDateAbsolute(lastupdatedtime)
              }
            >
              {isNaN(Date.parse(formatDate(lastupdatedtime)))
                ? ''
                : formatDistance(
                  new Date(formatDate(lastupdatedtime)),
                  new Date()
                ) + ' Ago'}
            </h3>
          </div>
        )}
      </div>
    </div>
  );
}

export default MapExplorer;
