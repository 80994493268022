import React from 'react';
import { makeStyles , withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';

import {Event} from "../../utils/event";
 

const useStyles = makeStyles({
  root: {
    width: '100%'
  }
});
const StyledButton = withStyles({
  root: {
    background: 'white',
    // borderRadius: 3,
    border: 0,
    color: '#014798',
    marginLeft:'0.2rem',
  },
  label:{
    textTransform:'none'
  }
})(Button);

// const typotheme = createMuiTheme({
//   // overrides: {
//   //   // Style sheet name ⚛️
//   //   MuiTypography: {
//   //     // Name of the rule
//   //     textTransform: 'none',
//   //   }
//   // },
//   typography: {
//     fontFamily:'Times New Roman',
//      fontSize: 14,
//   '@media (max-width:770px)': {
//       fontSize: 10,
//     },
//     fontWeight: 'bolder',
//     textTransform:'none'
//   },
//   label:{
//     textTransform:'none'
//   }

//   });


function News({news,state,restNews}) {
  const classes = useStyles();
  const utmLink = "?utm_source=Covdtracker&utm_medium=Storieslist&utm_campaign=April17"
  //console.log("length",news.length);
  if(state === 'ALL_INDIA'){
    return   <div>
    { news.length ? news.map( ( data , index) => {
        return <div style={{marginBottom:'1rem'}} key={index}> <Card className={classes.root}>
         <CardActionArea  href={data.link + utmLink} target="_blank" rel="noreferrer noopener"
         onClick={()=> {Event('ARTICLE_CLICK', `User clicked on article ${data.link}`)}}
         >
        <CardContent>

          <Typography gutterBottom variant="h3" component="h3" fontWeight="fontWeightBold">
          <Box fontWeight="fontWeightBold" m={0}  fontFamily="Times New Roman">
          {data.title}
          </Box>
          </Typography>
          
          <Typography variant="body2" component="div">
          <Box fontWeight="fontWeightRegular" fontFamily="Times New Roman" m={0} marginTop="0.5rem" paddingBottom="0">

          {data.description? data.description.substring(0 , 150)+"...":""}
          </Box>
          </Typography>
         </CardContent>
      </CardActionArea>
      <CardActions>
        <StyledButton  onClick={()=> {Event('ARTICLE_CLICK', `User clicked on article ${data.link}`)}}
        size="small" href={data.link + utmLink} target="_blank" rel="noopener noreferrer">
          Read More
        </StyledButton>
       </CardActions>

      <Divider />
    </Card>
    </div>
    }):""}
    </div>
  }
 else if(state !== 'ALL_INDIA' && news.length > 0){
  return (
    <div>
    { news.length ? news.map( ( data ,index) => {
        return <div style={{marginBottom:'1rem'}} key={index}> <Card className={classes.root}>
         <CardActionArea   onClick={()=> {Event('ARTICLE_CLICK', `User clicked on article ${data.link}`)}}
         href={data.link + utmLink} target="_blank" rel="noreferrer noopener">
        <CardContent>

          <Typography gutterBottom variant="h3" component="h3" fontWeight="fontWeightBold">
          <Box fontWeight="fontWeightBold" m={0}  fontFamily="Times New Roman">
          {data.title}
          </Box>
          </Typography>
          
          <Typography variant="body2" component="div">
          <Box fontWeight="fontWeightRegular" fontFamily="Times New Roman" m={0} marginTop="0.5rem" paddingBottom="0">

          {data.description? data.description.substring(0 , 150)+"...":""}
          </Box>
          </Typography>
         </CardContent>
      </CardActionArea>
      <CardActions>
        <StyledButton onClick={()=> {Event('ARTICLE_CLICK', `User clicked on article ${data.link}`)}}
       size="small" href={data.link + utmLink} target="_blank" rel="noopener noreferrer">
          Read More
        </StyledButton>
       </CardActions>

      <Divider />
    </Card>
    </div>
    }):""}
    <p style={{fontFamily:'Times New Roman' , fontWeight:'bold', marginBottom:'1rem'}}><i>Showing Positive Stories from Rest of India...</i></p>
    { restNews.length ? restNews.map( ( data ,index) => {
        return <div style={{marginBottom:'1rem'}} key={index}> <Card className={classes.root}>
         <CardActionArea  onClick={()=> {Event('ARTICLE_CLICK', `User clicked on article ${data.link}`)}}
        href={data.link + utmLink} target="_blank" rel="noreferrer noopener">
        <CardContent>

          <Typography gutterBottom variant="h3" component="h3" fontWeight="fontWeightBold">
          <Box fontWeight="fontWeightBold" m={0}  fontFamily="Times New Roman">
          {data.title}
          </Box>
          </Typography>
          
          <Typography variant="body2" component="div">
          <Box fontWeight="fontWeightRegular" fontFamily="Times New Roman" m={0} marginTop="0.5rem" paddingBottom="0">

          {data.description? data.description.substring(0 , 150)+"...":""}
          </Box>
          </Typography>
         </CardContent>
      </CardActionArea>
      <CardActions>
        <StyledButton   onClick={()=> {Event('ARTICLE_CLICK', `User clicked on article ${data.link}`)}}
        size="small" href={data.link + utmLink} target="_blank" rel="noopener noreferrer">
          Read More
        </StyledButton>
       </CardActions>

      <Divider />
    </Card>
    </div>
    }):""}
    </div>
  ) 
  }
  else if(state !== 'ALL_INDIA' && news.length === 0){
    return   <div>
      <p style={{fontFamily:'Times New Roman'  , fontSize:'14px', marginBottom:'1rem'}}>
      <i>Currently we do not have any positive stories from {state}, if you have any such story, please email us at <a href="mailto:editorial@thebetterindia.com" style={{display:'inline'}}>editorial@thebetterindia.com</a>
       <span style={{display:'block'}}>Showing Positive Stories from rest of India...</span></i>
      </p>
    { restNews.length ? restNews.map( ( data , index) => {
        return <div style={{marginBottom:'1rem'}} key={index}> <Card className={classes.root}>
         <CardActionArea   onClick={()=> {Event('ARTICLE_CLICK', `User clicked on article ${data.link}`)}}
         href={data.link + utmLink} target="_blank" rel="noreferrer noopener">
        <CardContent>

          <Typography gutterBottom variant="h3" component="h3" fontWeight="fontWeightBold">
          <Box fontWeight="fontWeightBold" m={0}  fontFamily="Times New Roman">
          {data.title}
          </Box>
          </Typography>
          
          <Typography variant="body2" component="div">
          <Box fontWeight="fontWeightRegular" fontFamily="Times New Roman" m={0} marginTop="0.5rem" paddingBottom="0">

          {data.description? data.description.substring(0 , 150)+"...":""}
          </Box>
          </Typography>
         </CardContent>
      </CardActionArea>
      <CardActions>
        <StyledButton   onClick={()=> {Event('ARTICLE_CLICK', `User clicked on article ${data.link}`)}}
         size="small" href={data.link + utmLink} target="_blank" rel="noopener noreferrer">
          Read More
        </StyledButton>
       </CardActions>

      <Divider />
    </Card>
    </div>
    }):""}
    </div>
  }
}

export default News;